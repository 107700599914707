// 要改的字段  前端组件统一字段
export const fieldName = [
  // 'type',
  // 'password',
  'cover',
  'exhibitionTime',
  'content',
  'userAvatar',
  'username',
  'lablelist'
];
// 后端给的字段需要修改的 视频 和 历史
export const video_attribute = ['videoCover', 'lastOnlineTime', 'title', 'userAvatar', 'username', 'videoLabelList'];
// 直播 和 关注
export const live_attribute = ['cover', 'lastOnlineTime', 'roomTitle', 'userAvatar', 'username', 'liveLabelList'];

export const fieldName_history = ['exhibitionTime', 'cover', 'pullAddressHls'];
// 历史回放
export const history_attribute = ['start_time', 'snap', 'hls'];

export const typeText = {
  2: '回看',
  3: '视频上传',
  4: '无人机回看'
};
