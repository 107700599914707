<template>
  <div class="attention-and-watch" >
    <div
      class="single"
      v-for="(item, index) in list"
      @click="selectPlayer(item)"
      :key="item.id"
    >
      <a class="single-top">
        <span>{{ item.type }}</span>
        <div class="lock-box" v-if="item.password">
          <img src="../../../assets/images/live-broadcast/lock.png" alt="" />
        </div>
        <div class="single-top-conetent" >
          <span class="player"></span>
          <img :src="item.cover" alt="" />
          <p>
            <template > 直播开始时间： </template>
            {{ item.exhibitionTime }}
          </p>
        </div>
      </a>
      <div class="single-bottom" >
        <h6>{{ item.content }}</h6>
        <div class="anchor-information">
          <div class="anchor-name">
            <a-avatar :size="20" :src="item.userAvatar" />
            <span>{{ item.nickName }}</span>
          </div>
        </div>
        <div class="live-tags">
          <span v-for="(tag, i) in item.lablelist" :key="i">{{ tag.labelName }}</span>
        </div>
        <slot name="delete" :item="item"></slot>
      </div>
      
    </div>
  </div>
</template>

<script>
import { ClockCircleOutlined } from '@ant-design/icons-vue';
export default {
  components: {
    ClockCircleOutlined
  },
  props: {
    list: Array,
  },
  setup(props, context) {
    const selectPlayer = params => {
      context.emit('select-player', params);
    };

    return {
      selectPlayer
    };
  }
};
</script>

<style lang="less" scoped>
.attention-and-watch {
  display: grid;
  grid-template-columns: repeat(5, 264px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  .single {
    background-color: #fff;
    width: 264px;
    border-radius: 10px;
    .single-top {
      width: 100%;
      height: 148px;
      display: block;
      position: relative;
      & > span {
        position: absolute;
        width: 58px;
        top: 10px;
        text-align: center;
        color: #fff;
        left: 10px;
        background-color: #23d79f;
        border-radius: 3px;
        z-index: 1;
      }

      .lock-box {
        position: absolute;
        width: 27px;
        height: 27px;
        right: 9px;
        top: 9px;
        z-index: 1;
      }

      .single-top-conetent {
        width: 100%;
        height: 100%;
        position: relative;
        p {
          position: absolute;
          bottom: 0;
          text-align: center;
          width: 100%;
          font-size: 12px;
          padding: 4px;
          padding-right: 20px;
          color: #ffffff;
          background-color: rgba(0, 0, 0, 0.3);
        }
        .player {
          width: 45px;
          display: flex;
          height: 45px;
          background-color: rgba(0, 0, 0, 0.15);
          border-radius: 50%;
          border: 1px solid #ffffff;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          align-items: center;
          justify-content: center;
        }
        img {
          border-radius: 10px;
        }
        .player::after {
          display: block;
          content: '';
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-left: 13px solid #fff;
          margin-left: 13px;
        }
      }
    }
    .single-bottom {
      color: #333333;
      padding: 10px;
      h6 {
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 175px;
      }
      .anchor-information {
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .anchor-name {
          display: flex;
          align-items: center;
          .ant-avatar {
            margin-right: 5px;
          }
        }
        .ant-btn {
          height: 28px;
          border-color: #d4d4d4;
          background-color: #d4d4d4;
          padding: 0 10px;
          font-size: 12px;
        }
      }
      .live-tags {
        margin-top: 15px;
        span {
          border-radius: 3px;
          padding: 1px 5px;
          margin-right: 5px;
          font-size: 12px;
          color: #999;
          border: 1px solid;
        }
      }
    }
  }

  &:last-of-type {
    margin: 0;
  }
}
.attention-history {
  grid-row-gap: 65px;
  .single_title {
    position: relative;
    .single-history-content {
      position: absolute;
      top: -35px;
      left: 27px;
      span:first-of-type {
        color: #ff872c;
        margin-right: 10px;
      }
    }
  }
  .single {
    .single-top-conetent {
      p {
        text-align: right !important;
        border-radius: 0 0 10px 10px;
      }
    }
  }
}
</style>
