<template>
  <div class="wrapper inspection_wrapper">
    <ul :class="['inspection_content', 'live-list', 'live-list_' + screenType]">
      <li
        :class="['live-box', 'live-box_' + screenType]"
        v-for="(live, i) in liveList"
        :key="i"
      >
        <flv-player v-if="live.id" :player="live" :ref="setItemRef"></flv-player>
        <span class="no-live" v-else>等待画面进入</span>
        <template v-if="live.id">
          <div class="live-title" v-if="live.id">{{ live.title }}</div>
          <RedoOutlined class="button-eve" @click="reconnectionButton(i)"  />
          <!-- <a-button type="primary" class="button-eve" @click="evenwheat(live)">{{ live.callType == 0 ? '连麦' : live.callType == 1 ? '退出连麦' : '当前主播正在连麦' }}</a-button> -->
          <a-popconfirm
            title="确定关闭该窗口吗?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleConfirmCloseView(live, i)"
          >
            <i class="icon icon-close" @click.stop=""></i>
          </a-popconfirm>
          <template v-if="live.type === 1">
            <a-popconfirm
              title="确定强制关闭该直播吗?"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleCloseLive(live, i)"
            >
              <i class="icon icon-exit" @click.stop></i>
            </a-popconfirm>
          </template>
        </template>
        <div class="btn btn-enter" @click.stop="handleEnterLive(live)" v-if="live.id">进入直播间</div>
        <div class="btn btn-switch" @click.stop="handleOpenModal('update', live, i)">切换直播</div>
      </li>
    </ul>

    <div class="inspection_footer">
      <a-radio-group v-model:value="screenType">
        <a-radio-button class="btn" :value="btn.type" v-for="(btn, i) in btns" :key="i">
          {{ btn.value }}
        </a-radio-button>
      </a-radio-group>
      <a-button type="primary" class="btn btn-add" @click="handleOpenModal('add')">增加直播</a-button>
    </div>

    <a-modal :visible="visible" @cancel="handleCancel" @ok="handleOk" class="inspection_modal" width="877px">
      <div class="live-conetnt">
        <search-box :placeholder="'搜索直播房间名'" @getList="getList"></search-box>
        <div class="list-player">
          <watch-item
            v-if="Boolean(videoList?.length)"
            :list="videoList"
            @select-player="handleSelectPlayer"
          />
          <a-empty v-else>
            <template #description>
              <p>暂无直播</p>
            </template>
          </a-empty>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs, watch, ref, provide, onBeforeUpdate, onUpdated } from 'vue';
import { useRouter } from 'vue-router';
import { SearchBox, LiveRoom, WatchItem, FlvPlayer } from './components';
import api from '@/services';
import { getLabelList } from '@/store/publicFunction.js';
import { FormatTime } from '@/utils/tool';
import { fieldName , live_attribute } from '@/store/fieldConfiguration';
import { leave, options, join } from '@/utils/evenwheat.js';
import { message } from 'ant-design-vue';
import { RedoOutlined } from '@ant-design/icons-vue';
import io from 'socket.io-client';
import { path } from '@/config';
export default {
  components: {
    WatchItem,
    SearchBox,
    LiveRoom,
    FlvPlayer,
    RedoOutlined
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      liveList: [], // 分屏列表
      videoList: [],
      screenType: 4,
      isDialog: false,
      checkIndex: null,

      liveSocket: null
    });

    // 条件筛选表单
    const formState = reactive({
      curPage: 1,
      liveLabelList: [],
      
      roomUserIds: [],
      status: 1,
    });

    // 弹框的显示和隐藏
    const modal = reactive({
      visible: false,
      close: false,
      type: '',
      title: '',
      content: '',
      player: {}
    });

    const userinfo = JSON.parse(localStorage.getItem('SS_userinfo'));
    const initSocket = (params) => {
      state.liveSocket = io(path, {
        query: `method=splitScreen&roomId=${params.roomId}&userName=${userinfo.userName}`
      });

      state.liveSocket.on('connect', data => {

      });

      const implementFuntion = {
        '07': async data => {
          const msg = JSON.parse(data.msgContent);
          if (!msg.attitude) {
            params.callType = 0
            // content.emit('isLianmai', '连麦');
            return false;
          }
          if (msg) {
            options.appid = msg?.appId;
            options.token = msg?.token;
            console.log(String(data?.roomId))
            options.channel = String(data?.roomId);
            try {
              await join();
              // params.callType = 1
              // getVideoResourcesAllPage();
              // content.emit('isLianmai', '退出连麦');
              message.success('连麦成功');
            } catch (error) {
              console.error(error);
            }
          }
        },
        '12': () => {
          LianmaiOut(params.roomId)
          // leave();
          // getVideoResourcesAllPage();
          // params.callType = 0
        },
      };

      state.liveSocket.on('receiveMsg', data => {
        console.log('接收到了数据', data);
        // implementFuntion[data.msgType](data);
      });

    ;
    };

    const setLiveList = data => {
      modal.visible ? (state.videoList = data) : (state.liveList = data);
      console.log(state.liveList, '====');
    };

    // 获取分屏巡检的列表
     const getLiveList = async () => {
      try {
        let params = {
          ...formState,
          pageSize: state.screenType
        };
        let { data, success } = await api.getLiveList(params)
        if (success) {
          setLiveList(data.records);
        }
      } catch (error) {
        console.log(error, '获取视频列表数据错误！');
      }
    };

    const params = ref({
      startOnlineTime: '',
      endOnlineTime: '',
      liveLabelList: [],
      roomUserIds: [],
      roomTitle: '',
    });
    provide('params', params);
    const getList = async () => {
      if (params.value.startOnlineTime) {
        params.value.startOnlineTime = `${FormatTime(params.value.startOnlineTime)} 00:00:00`;
      }
      if (params.value.endOnlineTime) {
        params.value.endOnlineTime = `${FormatTime(params.value.endOnlineTime)} 23:59:59`;
      }
      try {
        const { data } = await api.getLiveList(params.value);
        let records = [];
        records = data?.records?.map(item => {
          let paramsJson = JSON.stringify(item);
          live_attribute.forEach((oldKey, index) => {
            const reg = oldKey;
            paramsJson = paramsJson.replace(reg, fieldName[index]);
          });
          return JSON.parse(paramsJson);
        });
        state.videoList = records
      } catch (error) {
        console.error(error);
      }
    };

    // 选中窗口
    // const handleSelectView = (e, params, index) => {
    //   e.stopPropagation();
    //   if (state.checkIndex !== index) state.checkIndex = index;
    //   else state.checkIndex = null;
    // };
    // 进入直播间
    const handleEnterLive = params => {
      console.log(params)
      // 直播列表进去
      router.push({
        path: '/live-broadcast-watch',
        query: {
          pullFlow: params.videoResourcesVO.pullAddressFlv,
          id: params.id,
          roomUserId: params.roomUserId,
          liveType: params.liveType
        }
      });
    };

    // 确认关闭该窗口
    const handleConfirmCloseView = (params, index) => {
      console.log(params, index);
      state.liveList[index] = {};
      state.checkIndex = null;
      console.log(state.liveList, '====');
      // message.success('Click on Yes');
    };

    // 强制关闭直播间
    const handleCloseLive = async (params, index) => {
      try {
        const { success } = await api.closeLive(params.roomId);
        if (success) {
          state.checkIndex = null;
          state.liveList[index] = {};
        }
      } catch (error) {
        console.error(error);
      }
    };

    const handleOpenModal = (type, params, index) => {
      if (type === 'add') state.checkIndex = null;// 清空选中的窗口
      if (type === 'update') state.checkIndex = index; 
      modal.visible = true;
      modal.type = type;
      
      getList()
      console.log(type, params);
    };
    const handleSelectPlayer = params => {
      modal.player = params;
    };
    // 弹窗取消
    const handleCancel = () => {
      modal.visible = false;
    };

    const handleOk = () => {
      console.log(state.liveList)
      if (JSON.stringify(modal.player) === '{}') return; // 请先选中视频
      if (modal.type === 'add') {
        modal.player.id = state.liveList[0].id
        state.liveList[0] = modal.player;
      }
      if (modal.type === 'update') {
        modal.player.id = state.liveList[state.checkIndex].id
        state.liveList[state.checkIndex] = modal.player
      }
      modal.type = '';
      modal.visible = false;
      console.log(state.liveList, '====选中的车票');
    };

    // 获取标签筛选列表
    const labelList = ref(null);
    provide('labelList', labelList);
    const setLabelList = async () => {
      labelList.value = await getLabelList('getLiveLabelList');
    };

    const evenwheat = async (params) => {
      // callType 1 表示正在连麦 0 表示未连麦 2 正在处理连麦中
      console.log(params.callType)
      if (params.callType == 1) {
        LianmaiOut(params.roomId);
        // getVideoResourcesAllPage();
        getLiveList()
      } else if(params.callType == 0){
        const { code, msg, data } = await api.evenwheat(params.roomId);
        // initSocket(params)
        if (code == 500) {
          message.error(msg);
        } 
      } else {
        message.info('当前主播正在连麦')
      }
    }

    // 关闭连麦
    const LianmaiOut = async (roomId) => {
      leave();
      const { data } = await api.endCall(roomId);
      state?.liveSocket.disconnect();
    };

    onMounted(() => {
      getLiveList()
      // getVideoResourcesAllPage();
      setLabelList();
    });

    

    watch(
      () => state.screenType,
      () => {
        // getVideoResourcesAllPage();
        getLiveList()
      }
    );

    let itemRefs = []
    const setItemRef = el => {
      console.log(el)
      itemRefs.push(el)
    }
    onBeforeUpdate(() => {
      itemRefs = []
    })
    onUpdated(() => {
      console.log(itemRefs)
    })

    const reconnectionButton = (i) => {
      message.info('正在尝试重新连接，请稍后')
      itemRefs[i].reconnection()
    }

    return {
      ...toRefs(state),
      ...toRefs(formState),
      ...toRefs(modal),
      // handleSelectView,
      handleEnterLive,
      handleConfirmCloseView,
      handleCloseLive,

      handleOpenModal,
      handleSelectPlayer,
      handleCancel,
      handleOk,
      getList,
      evenwheat,

      reconnectionButton,
      setItemRef
    };
  },
  data() {
    return {
      btns: [
        { type: 4, value: '4分屏' },
        { type: 9, value: '9分屏' },
        { type: 16, value: '16分屏' },
        { type: 32, value: '32分屏' }
      ]
    };
  }
};
</script>

<style lang="less" scoped>
@icon_close: '~@imgs/inspection/icon_close.png';
@icon_exit: '~@imgs/inspection/icon_exit.png';

.inspection {
  &_wrapper {
    padding: 30px 20px;
  }

  &_content {
    width: 1400px;
    height: 814px;
    background: #ffffff;
    border-radius: 10px;
    padding: 20px;
    // display: grid;

    &.live-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .live-box {
        // display: inline-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #333333;
        border: 2px solid #333333;
        border-radius: 10px;
        margin-bottom: 10px;
        position: relative;
        overflow: hidden;
        cursor: pointer;

        .live-title {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 33px;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 10px 10px 0px 0px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 33px;
          text-align: center;
          white-space: nowrap;
        }

        .icon {
          position: absolute;
          right: 14px;
          width: 30px;
          height: 30px;
          // display: none;
          cursor: pointer;

          &-close {
            top: 4%;
            background: url('@{icon_close}') no-repeat center / 100% 100%;
          }

          &-exit {
            top: 16%;
            background: url('@{icon_exit}') no-repeat center / 100% 100%;
          }
        }

        .btn {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          padding: 0 20px;
          height: 27px;
          line-height: 27px;
          border-radius: 14px;
          text-align: center;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
          display: none;
          white-space: nowrap;

          &-enter {
            background: #007fff;
          }

          &-switch {
            top: calc(50% + 40px);
            background: #ff872c;
          }
        }

        .no-live {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 17px;
        }

        &:hover {
          .btn-enter,
          .btn-switch {
            display: block;
          }
        }

        &.checked {
          border-color: #ff872c;
        }
      }

      &_4 {
        .live-box {
          width: 670px;
          height: 377px;
        }
      }
      &_9 {
        .live-box {
          width: 444px;
          height: 250px;
          .icon {
            width: 20px;
            height: 20px;
          }
        }
      }
      &_16 {
        .live-box {
          width: 332px;
          height: 187px;
          .icon {
            width: 15px;
            height: 15px;
          }
        }
      }
      &_32 {
        .live-box {
          width: 333px;
          height: 328px;
          .icon {
            width: 22px;
            height: 22px;
          }

          &:nth-of-type(n + 5) {
            width: 188px;
            height: 106px;
            .icon {
              width: 9px;
              height: 9px;
            }
          }
        }
      }
    }
  }

  &_footer {
    margin-top: 10px;
    padding: 0 20px;
    width: 1400px;
    height: 70px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: #ffffff;
    border-radius: 10px;

    .btn {
      margin-left: 10px;

      &-add {
        margin-left: 30px;
      }
    }
  }
}
.inspection_modal {
  width: 877px !important;
}

.list-player {
  height: 500px;
  overflow: scroll;
}
.attention-and-watch {
  grid-template-columns: repeat(3, 264px);
}
//  inspection_modal
::v-deep(.inspection_modal) {
  width: 877px !important;
  .ant-modal-content {
    overflow: hidden;
  }
  .ant-modal-footer {
    padding: 0;

    button {
      height: 28px;
      width: 74px;
      margin: 0;
    }
    button:first-child {
      margin-right: 25px;
    }
  }
}
.button-eve {
  position: absolute;
  top: 20px;
  right: 55px;
  font-size: 22px;
  z-index: 999;
  color: rgba(255,255,255,0.6);
}
</style>
