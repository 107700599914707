<template>
  <!-- 视频播放 || 直播 -->
  <div class="player-wrapper">
    <div :id="`player-${player.id}`" class="player" style="width: 100%;"></div>
  </div>
  <div></div>
</template>

<script>
import { nextTick, onMounted } from 'vue';

export default {
  props: {
    player: { type: Object }
  },
  setup(props) {
    const createPlayer = () => {
      let videoObject = {
        container: `#player-${props.player.id}`, //容器的ID或className
        variable: 'player', // 播放函数名称
        autoplay: true,
        html5m3u8: props.player.type === 1 ? false : true,
        live: props.player.type === 1 ? true : false,
        video: props.player.type === 1 ? props.player.pullAddressRtmp : props.player.pullAddressHls
      };
      nextTick(() => {
        new ckplayer(videoObject);
      });
    };

    onMounted(() => {
      createPlayer();
    });
  }
};
</script>
<style lang="less" scoped>
.player-wrapper {
  width: 100%;
  height: 100%;
  .player {
    width: 100%;
    height: 100%;
  }
}
</style>
